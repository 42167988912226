@font-face {
  font-family: 'reckless_neue_trialbook';
  src: url('../assets/recklessneuetrial-book-webfont.woff2') format('woff2'), 
       url('../assets/recklessneuetrial-book-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'neue_haas_unicaregular';
  src: url('../assets/neuehaasunica-regular-webfont.woff2') format('woff2'),
       url('../assets/neuehaasunica-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$type--serif: 'reckless_neue_trialbook', serif;
$type--sans: 'neue_haas_unicaregular', sans-serif;

@mixin body {
  @include fluid-type(320px, 1920px, 19px, 26px);
  font-family: $type--sans;
  letter-spacing: 0.0026em;
  line-height: 1.25;
}

@mixin body-small {
  @include fluid-type(320px, 1920px, 16px, 18px);
  font-family: $type--sans;
  letter-spacing: 0.0018em;
  line-height: 1.3;
}

@mixin heading {
  @include fluid-type(320px, 1920px, 20px, 26px);
  font-family: $type--serif;
  font-weight: 400;
  letter-spacing: -0.0105em;
  line-height: 1.1;
}

@mixin animated-link {
  position: relative;

  &:after {   
    content: "";
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    background: $black;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }

  &:hover:after {
    width: 100%;
  }
}

h1 {
  @include fluid-type(1024px, 1920px, 40px, 70px);
  font-family: $type--serif;
  font-weight: 400;
  letter-spacing: -0.021em;
  line-height: 1.05;
  margin-top: 0;
  margin-bottom: 5vh;
}

h2 {
  @include body;
  margin-top: 0;
  margin-bottom: 2vh;
  font-weight: 400;
}

h3 {
  @include heading;
  margin-top: 0;
  margin-bottom: 1.5vh;
}

p {
  margin: 0 0 25px;
}

a {
  color: $black;
  text-decoration: none;
  display: inline-block;
  @include animated-link;
}

@media only screen and (max-width: 1024px) {
  h1 {
    margin-bottom: 6vh;
  }
}

@media only screen and (max-width: 768px) {
  h1 {
    @include fluid-type(320px, 768px, 30px, 60px);
  }

  h2, h3 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 15px;
  }
}