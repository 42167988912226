@import './partials/variables';
@import './partials/mixins';
@import './partials/type';

* {
  padding: 0;
  margin: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: $type--sans;
  font-size: 1rem;
}

button {
  appearance: none;
  border: 0;
  background: none;
  border-radius: 0;
  outline: none;
  font-family: $type--sans;
  cursor: pointer;
}

// grid
.grid {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 105px auto 100px;
  height: 100vh;
}

// header
.header {
  background-color: $white;
  grid-column-start: 1;
  grid-column-end: 2;
  padding: map-get($padding, 'large');
  display: flex;
  align-items: flex-start;
}

// logo
.logo {
  width: 330px;

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
}

// nav
.nav {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 3;
  display: flex;
  align-items: center;
  padding-left: map-get($padding, 'small');
  padding-right: map-get($padding, 'small');
  overflow: hidden;
  opacity: 0;
  animation: fade-in 1s forwards;
  animation-delay: 1s;

  li {
    display: inline-block;
    padding: map-get($padding, 'small') .9vw;

    button,
    a {
      @include body;
      @include animated-link;
    }
  }
}

// reveal text
.reveal-text {
  opacity: 0;
  animation: fade-in-left 2s forwards .12s;
}

// main
.main {
  background-color: $white;
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 4;
  padding: map-get($padding, 'large');
  border-left: 1px solid $black;
  position: relative;
  z-index: 1;
  overflow: auto;
  transition: transform 700ms $transition--ease-in-out;

  &.is-under {
    transform: translate(-10vw, 0);
  }
}

// accordion
.accordion__section {
  overflow: hidden;
  border-bottom: 1px solid $black;

  &:first-of-type {
    border-top: 1px solid $black;
  }

  &-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    padding: map-get($padding, 'large') 0;
    padding-right: 20px;
    cursor: pointer;
  }
  
  &-content {
    overflow: hidden;
    max-height: 0;
  }

  &.is-active {
    & > .accordion__section-content {
      max-height: 100%;
    }
  }
}

// panel
.panel {
  width: calc(50vw - 40px);
  height: calc(100vh - 40px);
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  background-color: $orange;
  transform: translate(50vw, 0);
  padding: map-get($padding, 'small');
  transition: transform 640ms $transition--ease-in-out;
  overflow: auto;

  &.is-open {
    transform: translate(0, 0);

    & + .panel__overlay {
      opacity: 1;
      visibility: visible;
      transition: all 640ms;
      cursor: pointer;
    }
  }

  &--about {
    padding-top: map-get($padding, 'small');

    & > div {
      @include body;

      &:first-of-type {
        width: calc(70% - 40px);
        float: right;
      }
    }

    & > .accordion {
      padding: map-get($padding, 'small');
      clear: both;

      // team grid layout
      article {
        margin: 0 -20px;
        width: calc(100% + 40px);
        overflow: hidden;
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 20px;

        & > div {
          padding: 0 map-get($padding, 'small');
          float: left;
        }

        p {
          @include body-small;
        }
      }
    }

    .about-image {
      clear: both;
      width: 100%;
      margin-bottom: 40px;
      position: relative;
      height: 0;
      padding-bottom: 75.76%;
    }

    img {
      width: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &--contact {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &--studio,
  &--contact {
    float: left;
    padding-top: map-get($padding, 'small');
    width: calc(70% - 40px);
    height: calc(100% - 40px);

    p,
    li {
      @include body;
    }

    ul {
      list-style: none;
    }

    li {
      position: relative;
      padding-left: 30px;
    }

    li:before {
      content: "—";
      position: absolute;
      top: 0;
      left: 0;
    }

    span {
      @include body-small;
    }
  }

  &__close {
    width: 30%;
    float: left;
    padding: map-get($padding, 'small');
    
    button {
      @include body;
    }
  }

  &__overlay {
    z-index: 1;
    background-color: transparent;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 640ms 240ms;
    cursor: default;
  }
}

.location {
  display: inline-flex;
  align-items: center;
  margin-bottom: 5vh;
  @include body;
  opacity: 0;
  animation: fade-in 1s forwards;
  animation-delay: 1s;

  &:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: $black;
    border-radius: 50%;
    margin-right: 10px;
    position: relative;
  }
}

.blink {
  animation: blink 1s infinite;
}

.subscribe-form {
  opacity: 0;
  animation: fade-in 1s forwards;
  animation-delay: 1s;
}

form {
  border-bottom: 1px solid $black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}

input {
  border: 0;
  border-radius: 0;
  appearance: none;
  outline: none;
  height: 100%;
  padding: 8px 0;
  background: none;
  @include body-small;

  &[type=email] {
    flex-grow: 1;
  }
}

[type=submit] {
  cursor: pointer;
  @include body-small;
  @include animated-link;
}

// responsive
@media only screen and (max-width: 768px) {
  .grid {
    grid-template-columns: 100%;
    grid-template-rows: 57px auto 65px;
  }

  .logo {
    width: 207px;
  }

  .header {
    grid-row-start: 1;
    grid-row-end: 1; 
    padding: map-get($padding, 'small');
    align-items: flex-end;
    border-bottom: 1px solid $black;
  }

  .main {
    border-left: 0;
    grid-column-start: 1;
    grid-row-start: 2;
    grid-row-end: 2;
    padding: map-get($padding, 'small');

    &.is-under {
      transform: none;
    }
  }

  .nav {
    grid-row-start: 3;
    grid-row-end: 3;
    padding: 0;
    padding: map-get($padding, 'xsmall');

    li {
      padding: map-get($padding, 'xsmall');
    }
  }

  .panel {
    width: 100%;
    transform: translate(100vw, 0);
    padding: 0;
  }

  .location:before {
    width: 12px;
    height: 12px;
    margin-right: 7px;
  }
}

@media only screen and (max-width: 1024px) {
  .panel--about .accordion article {
    display: block;

    div:not(:last-of-type) {
      margin-bottom: map-get($padding, medium);
    }
  }

  .location {
    margin-bottom: 3vh;
  }
}

@media only screen and (max-width: 768px) {
  .panel {
    height: 100%;

    &--about {
      .accordion {
        margin-bottom: map-get($padding, large);
      }
    }

    &--about > div {
      &:first-of-type {
        clear: both;
        width: calc(100% - 40px);
        float: none;
        padding: 0 20px;
      }
    }

    &--studio,
    &--contact {
      float: none;
      width: auto;
      clear: both;
      padding: map-get($padding, small);
      height: calc(100% - 110px);
    }
  }

  .accordion__section {
    &-heading {
      padding: map-get($padding, small) 0;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
}

@media only screen and (max-width: 530px) {
  form {
    width: 100%;
  }
}
