// colors
$blue: #0079A3;
$orange: #FA753C;
$white: #fff;
$black: #101010;

// breakpoints
$breakpoints: (
  small: 0,
  medium: 640px,
  xmedium: 980px,
  large: 1100px,
  xlarge: 1400px,
  xxlarge: 1800px
);

// padding
$padding: (
  'xlarge': 80px,
  'large': 40px,
  'medium': 25px,
  'small': 20px,
  'xsmall': 10px
);

// transitions
$transition--ease-in-out: cubic-bezier(0.65,0.05,0.36,1);

// animations
@keyframes blink {
	0%  { opacity: 0 }
	49% { opacity: 0 }
	50% { opacity: 1 }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in-left {
  0% {
    opacity: 0;
    transform: translate(20px, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}